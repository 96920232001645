<template>
    <div class="ytm-board-menu-wrapper">
        <BoardMenuItem
            icon="shape-2-line"
            title="Режим выделения фигур"
            :action="() => {
                    currMode === MODE_SELECTING ? this.canvas.ytmCanvas.stopSelecting() : this.canvas.ytmCanvas.startSelecting();
                }"
            :isActive="() => { return currMode === MODE_SELECTING; }"
        />
        <div class="ytm-board-menu-divider"/>
        <BoardMenuItem
            icon="pencil-fill"
            title="Рисовать"
            :action="() => {
                    currMode === MODE_DRAWING ? this.canvas.ytmCanvas.stopDrawing() : this.canvas.ytmCanvas.startDrawing();
                }"
            :isActive="() => { return currMode === MODE_DRAWING; }"
        />
        <BoardMenuItem
            icon="eraser-line"
            title="Стереть"
            :action="() => {
                    currMode === MODE_ERASING ? this.canvas.ytmCanvas.stopErasing() : this.canvas.ytmCanvas.startErasing();
                }"
            :isActive="() => { return currMode === MODE_ERASING; }"
        />
        <BoardMenuItem
            icon="subtract-fill"
            title="Линия"
            :action="() => {
                    currMode === 'line' ? this.canvas.ytmCanvas.stopLine() : this.canvas.ytmCanvas.startLine();
                }"
            :isActive="() => { return currMode === 'line'; }"
        />
        <BoardMenuItem
            icon="checkbox-blank-line"
            title="Прямоугольник"
            :action="() => {
                    currMode === 'rectangle' ? this.canvas.ytmCanvas.stopRectangle() : this.canvas.ytmCanvas.startRectangle();
                }"
            :isActive="() => { return currMode === 'rectangle'; }"
        />
        <BoardMenuItem
            icon="checkbox-blank-circle-line"
            title="Круг"
            :action="() => {
                    currMode === 'circle' ? this.canvas.ytmCanvas.stopCircle() : this.canvas.ytmCanvas.startCircle();
                }"
            :isActive="() => { return currMode === 'circle'; }"
        />
        <BoardMenuItem
            icon="text"
            title="Текст"
            :action="() => {
                    currMode === 'text' ? this.canvas.ytmCanvas.stopText() : this.canvas.ytmCanvas.startText();
                }"
            :isActive="() => { return currMode === 'text'; }"
        />
        <BoardMenuItem
            icon="image-add-line"
            title="Изображение"
            :action="() => {
                    if (currMode !== 'selecting') {
                        this.canvas.ytmCanvas.startSelecting();
                    }
                    this.canvas.showImageUploadModal = true;
                }"
        />
        <div
            class="ytm-board-menu-divider"
            v-if="currMode === 'drawing' || currMode === 'line' || currMode === 'circle' || currMode === 'rectangle' || currMode === 'text'"
        />
        <div
            class="ytm-board-menu-dropdown"
            v-if="currMode === 'drawing' || currMode === 'line' || currMode === 'circle' || currMode === 'rectangle' || currMode === 'text'"
        >
            <BoardMenuItem
                icon="palette-line"
                title="Палитра"
                :action="() => { }"
            />
            <div class="ytm-board-menu-dropdown-content-wrapper">
                <div class="ytm-board-menu-dropdown-content">
                    <BoardMenuItem
                        v-for="color in colors"
                        :key="color.name"
                        :icon="color.value !== 'white' ? 'drop-fill' : 'drop-line'"
                        :title="color.name"
                        :action="() => { this.canvas.ytmCanvas.setColor(color.value); }"
                        :isActive="() => { return this.canvas.ytmCanvas.getColor() === color.value; }"
                        :color="color.value === 'white' ? 'black' : color.value"
                        style="margin-right: 0"
                    />
                </div>
            </div>
        </div>
        <div
            class="ytm-board-menu-dropdown"
            v-if="currMode === 'drawing' || currMode === 'line' || currMode === 'circle' || currMode === 'rectangle'"
        >
            <BoardMenuItem
                icon="focus-line"
                title="Толщина"
                :action="() => { }"
            />
            <div class="ytm-board-menu-dropdown-content-wrapper">
                <div class="ytm-board-menu-dropdown-content">
                    <BoardMenuItem
                        icon="subtract-line"
                        title="Уменьшить"
                        :action="() => { this.canvas.ytmCanvas.changeBrushWidth(-1); }"
                        color="black"
                    />
                    <div style="display: table; margin-left: 3px; margin-right: 8px">
                        <div style="display: table-cell; vertical-align: middle">
                            {{ canvas.ytmCanvas.commonSettings.brushWidth }}
                        </div>
                    </div>
                    <BoardMenuItem
                        icon="add-line"
                        title="Увеличить"
                        :action="() => { this.canvas.ytmCanvas.changeBrushWidth(+1); }"
                        color="black"
                    />
                </div>
            </div>
        </div>
        <div class="ytm-board-menu-divider"/>
        <BoardMenuItem
            icon="arrow-go-back-line"
            title="Отменить"
            :action="() => {this.canvas.ytmCanvas.performUndo()}"
        />
        <BoardMenuItem
            icon="arrow-go-forward-line"
            title="Вернуть отменённое"
            :action="() => {this.canvas.ytmCanvas.performRedo()}"
        />
    </div>
</template>

<script>
import "./menu.css";
import BoardMenuItem from "./BoardMenuItem";
import {MODE_SELECTING, MODE_DRAWING, MODE_CIRCLE, MODE_ERASING, MODE_IMAGE, MODE_LINE, MODE_RECTANGLE, MODE_TEXT} from "@/components/MaterialsV2/BoardCellV2/canvas/const";

export default {
    name: 'MenuBar',
    components: {
        BoardMenuItem,
    },
    props: {
        canvas: {
            type: Object,
            required: true,
        },
    },
    computed: {
        currMode() {
            return this.canvas.ytmCanvas.commonSettings.mode;
        },
    },
    data() {
        return {
            show_palette: false,
            MODE_SELECTING, MODE_DRAWING, MODE_ERASING, MODE_LINE, MODE_CIRCLE, MODE_RECTANGLE, MODE_TEXT, MODE_IMAGE,
            colors: [
                { name: 'Красный', value: 'red' },
                { name: 'Розовый', value: 'pink' },
                { name: 'Оранжевый', value: '#ffc107' },
                { name: 'Жёлтый', value: 'yellow' },
                { name: 'Лаймовый', value: 'lime' },
                { name: 'Зеленый', value: 'green' },
                { name: 'Голубой', value: 'aqua' },
                { name: 'Синий', value: 'blue' },
                { name: 'Фиолетовый', value: 'violet' },
                { name: 'Белый', value: 'white' },
                { name: 'Серый', value: 'gray' },
                { name: 'Чёрный', value: 'black' },
            ],
        };
    },
};
</script>

<style scoped>

</style>