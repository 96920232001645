<template>
    <div>
        <ImageUploadModal
            v-if="showImageUploadModal"
            :boardId="boardId"
            @reset_modal="showImageUploadModal = false"
            @imageUploaded="addImage"
        />
        <div
            ref="canvasWrapperComponent"
            class="ytm-canvas-wrapper"
            tabindex="1000"
            @click.prevent="hideContextMenu"
            @keydown.prevent.meta.z.exact="performUndo"
            @keyup.prevent.ctrl.z.exact="performUndo"
            @keydown.prevent.meta.shift.z.exact="performRedo"
            @keyup.prevent.ctrl.shift.z.exact="performRedo"
            @keyup.delete="deleteActiveObjects"
            @keydown.prevent.meta.c.exact="copyActiveObjects"
            @keyup.prevent.ctrl.c.exact="copyActiveObjects"
            @keydown.prevent.meta.v.exact="pasteObjects"
            @keyup.prevent.ctrl.v.exact="pasteObjects"
        >
            <canvas :id="cellId + '_new'" class="ytm-canvas"/>
            <MenuBar v-if="isActive && ready" :canvas="this"
                     :class="{'ytm-canvas-menu': !showContextMenu, 'ytm-canvas-menu-invisible': showContextMenu}"
            />
            <div v-if="ready"
                 ref="contextMenuComponent"
                 class="ytm-hidden"
                 style="cursor: pointer; position: absolute; top: 1rem; left: 1rem; z-index: 1"
                 @contextmenu.prevent
                 @click.prevent.stop
            >
                <ContextMenu :canvas="this" :active="isActive"/>
            </div>
            <Resizer :show="isActive && ready" @changeHeight="setCanvasHeight" @fixHeight="sendNewAR"/>
        </div>
    </div>
</template>

<script>
import "@/components/redesign/Board/board.css";
import {YTMCanvas} from "@/components/MaterialsV2/BoardCellV2/canvas/canvas";
import {computed, nextTick, onMounted, ref, toRaw, watch} from "vue";
import ContextMenu from "@/components/redesign/Board/ContextMenu.vue";
import MenuBar from "@/components/redesign/Board/MenuBar.vue";
import ImageUploadModal from "@/components/redesign/Board/ImageUploadModal.vue";
import Resizer from "@/components/redesign/Board/Resizer.vue";

export default {
    name: 'Board',
    components: {Resizer, ImageUploadModal, MenuBar, ContextMenu},
    props: {
        boardId: {
            type: String,
            required: true,
        },
        cellId: {
            type: String,
            required: true,
        },
        active: {
            type: Boolean,
            required: true,
        },
        collaboration: {
            type: Boolean,
            default: false,
        },
        ar: {
            type: Number,
            default: 0.4,
        },
        objects: {
            type: Array,
            default: [],
        },
    },
    setup(props, {emit}) {
        const ytmCanvas = new YTMCanvas(props.boardId, props.cellId);
        const canvasWrapperComponent = ref(null);

        onMounted(() => {
            ytmCanvas.setCanvas();
            ytmCanvas.onObjectAddition((object) => emit('onObjectAddition', object));
            ytmCanvas.onObjectTransform((objects) => emit('onObjectTransform', objects));
            ytmCanvas.onObjectDeletion((uuids) => emit('onObjectDeletion', uuids));
            ytmCanvas.onMouseMove((x, y) => emit('onMouseMove', {x, y}));

            canvasWrapperComponent.value.style.height = (props.ar * canvasWrapperComponent.value.clientWidth).toString() + 'px';
            const changeDim = () => {
                ytmCanvas.fabricCanvas.setDimensions({
                    width: canvasWrapperComponent.value.clientWidth,
                    height: canvasWrapperComponent.value.clientWidth * props.ar,
                });
            };
            changeDim();
            window.addEventListener('resize', changeDim);
        });

        const setActive = (value) => {
            if (!ytmCanvas) {
                return;
            }
            if (value) {
                ytmCanvas.makeObjectsDraggable();
                ytmCanvas.makeObjectsScaleable();
            } else {
                ytmCanvas.makeObjectsUndraggable();
                ytmCanvas.makeObjectsUnscaleable();
            }
        };

        const isActive = computed(() => props.active);
        watch(isActive, value => { setActive(value) });

        const contextMenuComponent = ref(null);
        const contextMenuSettings = ref(null);
        const showContextMenu = computed(() => !!contextMenuSettings.value && contextMenuSettings.value.show);
        const hideContextMenu = () => {
            if (ytmCanvas) {
                ytmCanvas.hideContextMenuByClickOutside();
            }
        };

        const ready = ref(false);

        onMounted(() => {
            const objects = props.objects.map(obj => toRaw(obj));
            ytmCanvas.addObjects(objects).then(() => {
                setActive(isActive.value);
                contextMenuSettings.value = ytmCanvas.contextMenuSettings;
                ready.value = true;
                emit('ready');
                nextTick(() => {
                    ytmCanvas.setContextMenuComponent(contextMenuComponent);
                });
            });
        });

        const showImageUploadModal = ref(false);
        const addImage = (url) => {
            if (isActive.value && ytmCanvas) {
                ytmCanvas.addImageByURL(url);
            }
        };

        const performUndo = () => { if (isActive.value) ytmCanvas.performUndo() };
        const performRedo = () => { if (isActive.value) ytmCanvas.performRedo() };
        const deleteActiveObjects = () => { if (isActive.value) ytmCanvas.deleteActiveObjects() };
        const copyActiveObjects = () => { ytmCanvas.copyActiveObjects() };
        const pasteObjects = () => { if (isActive.value) ytmCanvas.pasteObjects() };

        const handlers = {};

        if (props.collaboration) {
            handlers.handleObjectAddition = (payload) => { if (isActive.value) ytmCanvas.handleObjectAddition(payload) };
            handlers.handleObjectTransform = (payload) => { if (isActive.value) ytmCanvas.handleObjectTransform(payload) };
            handlers.handleObjectDeletion = (payload) => { if (isActive.value) ytmCanvas.handleObjectDeletion(payload) };
            handlers.handleMouseMove = (payload) => { if (isActive.value) ytmCanvas.handleMouseMove(payload) };
            handlers.removeCursor = (payload) => { if (isActive.value) ytmCanvas.removeCursor(payload) };
        }

        const setCanvasHeight = (delta) => {
            const height = canvasWrapperComponent.value.clientHeight + delta;
            if (height / canvasWrapperComponent.value.clientWidth < 0.05) {
                return;
            }
            canvasWrapperComponent.value.style.height = height.toString() + 'px';
            ytmCanvas.fabricCanvas.setDimensions({
                width: canvasWrapperComponent.value.clientWidth,
                height: canvasWrapperComponent.value.clientHeight,
            });
        };

        const sendNewAR = () => {
            emit('newAR', canvasWrapperComponent.value.clientHeight / canvasWrapperComponent.value.clientWidth);
        };

        const updateMaterial = (payload) => {
            const newAR = payload.material.ar;
            canvasWrapperComponent.value.style.height = (canvasWrapperComponent.value.clientWidth * newAR).toString() + 'px';
            ytmCanvas.fabricCanvas.setDimensions({
                width: canvasWrapperComponent.value.clientWidth,
                height: canvasWrapperComponent.value.clientHeight,
            });
        };

        return {
            canvasWrapperComponent, ytmCanvas,
            isActive, ready,
            contextMenuComponent, contextMenuSettings, showContextMenu, hideContextMenu,
            showImageUploadModal, addImage,
            performUndo, performRedo, deleteActiveObjects, copyActiveObjects, pasteObjects, ...handlers,
            setCanvasHeight, sendNewAR,
            updateMaterial,
        };
    },
};
</script>

<style scoped>

</style>