<template>
    <TransitionGroup name="ytm-horizontal-list">
        <slot/>
    </TransitionGroup>
</template>

<script>
export default {
    name: 'HorizontalListTransition',
};
</script>

<style>
.ytm-horizontal-list-move,
.ytm-horizontal-list-enter-active,
.ytm-horizontal-list-leave-active {
    transition: all .2s ease;
}
.ytm-horizontal-list-leave-to {
    transform: translateX(10rem);
    opacity: 0;
}
.ytm-horizontal-list-enter-from {
    transform: translateX(1rem);
    opacity: 0;
}
.ytm-horizontal-list-leave-active {
    position: absolute;
}
</style>