<template>
  <div
      class="ytm-slide-wrapper"
      :style="
        'min-width: ' + width + ';' +
        'max-width: ' + width + ';' +
        'left: ' + left + ';'
      "
  >
    <div class="ytm-slide">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slide',
  props: {
    per_page: {
      type: Number,
      default: () => 3,
    },
    cur_slide: {
      type: Number,
      required: true,
    },
  },
  computed: {
    width() {
      const result = '100% / ' + this.per_page;
      return 'calc(' + result + ')';
    },
    left() {
      const result = '-100% / ' + this.per_page + ' * ' + this.cur_slide;
      return 'calc(' + result + ')';
    },
  },
};
</script>

<style scoped>
.ytm-slide-wrapper {
  position: relative;
  transition: all ease 0.5s;
}

.ytm-slide {
  position: relative;
  height: 100%;
  max-height: 100%;
  margin: 0 5px;
}
</style>