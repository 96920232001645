export class Deque {
    constructor() {
        this.content = [];
    }

    get length() {
        return this.content.length;
    }

    pushFront(value) {
        this.content.unshift(value);
    }

    pushBack(value) {
        this.content.push(value);
    }

    popFront() {
        if (this.length !== 0) {
            return this.content.shift();
        }
    }

    popBack() {
        if (this.length !== 0) {
            return this.content.pop();
        }
    }

    clear() {
        this.content = [];
    }

    print() {
        for (const object of this.content) {
            console.log(object);
        }
    }
}