<template>
    <div>
        <input
                type="checkbox"
                :checked="isChecked"
                :value="modelValue"
                @input="updateInput"
                class="ytm-checkbox"
                :id="id"
        >
        <label :for="id" class="ytm-default-text">
            {{title}}
        </label>
    </div>
</template>

<script>
import "@/styles/style.css";

export default {
    name: 'Checkbox',
    props: {
        modelValue: {},
        id: {
            type: String,
            required: true,
        },
        title: {type: String},
        trueValue: {default: true},
        falseValue: {default: false},
    },
    computed: {
        isChecked() {
            return this.modelValue === this.trueValue;
        }
    },
    methods: {
        updateInput(event) {
            this.$emit('update:modelValue', event.target.checked ? this.trueValue : this.falseValue)
        },
    },
};
</script>

<style scoped>
.ytm-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.ytm-checkbox+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
}
.ytm-checkbox+label::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}
.ytm-checkbox:checked+label::before {
    border-color: #07A8F0;
    background-color: #FFFFFF;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2307A8F0' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
</style>