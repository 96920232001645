<template>
    <button
        class="menu-item"
        :class="{
        'is-active': isActive ? isActive() : null,
        'colored' : !!color,
        'colored-active': isActive ? isActive() && !!color : false
      }"
        @click="action"
        :title="title"
    >
        <svg class="remix">
            <use :xlink:href="`${remixiconUrl}#ri-${icon}`" />
        </svg>
    </button>
</template>

<script>
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg';

export default {
    name: 'BoardMenuItem',
    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        action: {
            type: Function,
            required: true,
        },
        isActive: {
            type: Function,
            default: null,
        },
        color: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            remixiconUrl,
        };
    },
};
</script>

<style scoped>
.menu-item {
    cursor: pointer;
    width: 1.75rem;
    height: 1.75rem;
    color: #0d0d0d;
    border: none;
    background-color: transparent;
    border-radius: 0.4rem;
    padding: 0.25rem;
    margin-right: 0.25rem;
}
.menu-item svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
}
.menu-item.is-active, .menu-item:hover {
    color: #fff;
    background-color: #0d0d0d;
}
.menu-item.colored {
    color: v-bind(color);
    background-color: #fff;
}
.menu-item.colored-active {
    background-color: lightgrey;
}
</style>