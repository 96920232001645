<template>
    <div :class="{'ytm-light-editor': true, 'ytm-disabled': !editable}" ref="editorWrapper">
        <MenuBar v-if="editor && editable" class="ytm-menu-bar-disappearing" :editor="editor"/>
        <EditorContent v-if="editor" :editor="editor"/>
    </div>
</template>

<script>
import {Editor, EditorContent} from "@tiptap/vue-3";
import {Document} from "@tiptap/extension-document";
import {Text} from "@tiptap/extension-text";
import {Paragraph} from "@tiptap/extension-paragraph";
import {Bold} from "@tiptap/extension-bold";
import {Italic} from "@tiptap/extension-italic";
import {Strike} from "@tiptap/extension-strike";
import {Code} from "@tiptap/extension-code";
import {HardBreak} from "@tiptap/extension-hard-break";
import {BulletList} from "@tiptap/extension-bullet-list";
import {OrderedList} from "@tiptap/extension-ordered-list";
import {ListItem} from "@tiptap/extension-list-item";
import {Link} from "@tiptap/extension-link";
import {Placeholder} from "@tiptap/extension-placeholder";
import MenuBar from "@/components/redesign/Form/MenuBar.vue";
import YTMFormula from "@/components/MaterialsV2/CollabText/YTMFormula/YTMFormula";

export default {
    name: 'LightEditor',
    components: {MenuBar, EditorContent},
    props: {
        modelValue: {},
        placeholder: {
            type: String,
            default: '',
        },
        editable: {
            type: Boolean,
            default: true,
        },
        autofocus: {
            type: [String, Number, Boolean],
            default: false,
        },
    },
    data() {
        return {
            editor: null,
            isFocused: false,
        };
    },
    mounted() {
        this.editor = new Editor({
            content: this.modelValue,
            extensions: [
                Document, Text, Paragraph, Bold, Italic, Strike, Code, HardBreak, BulletList, OrderedList, ListItem, Link,
                Placeholder.configure({
                    placeholder: this.placeholder,
                    emptyNodeClass: '',
                }),
                YTMFormula,
            ],
            editable: this.editable,
            autofocus: this.autofocus,
            onUpdate: (editor) => {
                this.$emit('update:modelValue', editor.editor.getHTML());
            },
            onFocus: () => {
                this.isFocused = true;
            },
            onBlur: () => {
                this.isFocused = false;
            },
        });
    },
    beforeUnmount() {
        // for animations: TipTap destroys content, but we save the "HTML snapshot"
        if (this.editor && !this.editor.isDestroyed) {
            const content = this.$refs.editorWrapper.innerHTML;
            this.$refs.editorWrapper.innerHTML = content;
            this.editor.destroy();
        }
    },
};
</script>

<style>
.ytm-light-editor {
    width: 100%;
    padding: 0.75rem 1.25rem;
    background: #F3F3F3;
    border-radius: 0.6rem;
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-style: normal;
    line-height: 116.6%;
    font-weight: 400;
}
.ytm-disabled {
    cursor: default;
}
@keyframes ytm-menu-bar-animation {
    0% {
        display: flex;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes ytm-menu-bar-animation-reverse {
    0% {
        display: flex;
        opacity: 1;
    }
    100% {
        display: none;
        opacity: 0;
    }
}
.ytm-menu-bar-disappearing {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    padding: 3px 5px 3px calc(5px + 0.25rem);
    background: white;
    display: none;
    flex: 0 0 auto;
    flex-wrap: nowrap;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 5px;
    opacity: 0;
    box-shadow: 0 0 10px 0 lightgrey;
    animation: ytm-menu-bar-animation-reverse 0.2s;
    z-index: 1000;
}
.ytm-light-editor:hover .ytm-menu-bar-disappearing {
    display: flex;
    opacity: 1;
    animation: ytm-menu-bar-animation 0.2s;
}
@media only screen and (max-width: 640px) {
    .ytm-menu-bar-disappearing {
        width: 100%;
        flex-wrap: wrap;
    }
}
</style>