<template>
    <transition
            name="expand"
            @enter="enter"
            @after-enter="afterEnter"
            @leave="leave"
    >
        <slot/>
    </transition>
</template>

<script>
export default {
    name: 'ExpandCollapseTransition',
    props: {
        heightPx: {
            type: Number,
            default: null,
        },
    },
    methods: {
        getTargetHeight(element) {
            if (this.heightPx) {
                return `${this.heightPx}px`;
            }
            const width = getComputedStyle(element).width;
            element.style.width = width;
            element.style.position = 'absolute';
            element.style.visibility = 'hidden';
            element.style.height = 'auto';
            const height = getComputedStyle(element).height;
            element.style.width = null;
            element.style.position = null;
            element.style.visibility = null;
            element.style.height = 0;
            getComputedStyle(element).height;
            return height;
        },
        enter(element) {
            element.style.height = 0;
            getComputedStyle(element).height;
            const height = this.getTargetHeight(element);
            requestAnimationFrame(() => {
                element.style.height = height;
            });
        },
        afterEnter(element) {
            let height = 'auto';
            if (this.heightPx) {
                height = `${this.heightPx}px`;
            }
            element.style.height = height;
        },
        leave(element) {
            if (!element.style.height || element.style.height === 'auto') {
                const height = getComputedStyle(element).height;
                element.style.height = height;
                getComputedStyle(element).height;
            }
            requestAnimationFrame(() => {
                element.style.height = 0;
            });
        },
    },
};
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
    transition: height .3s ease-in-out;
    overflow: hidden;
}
.expand-enter,
.expand-leave-to {
    height: 0;
}
</style>