<template>
    <div class="ytm-board-menu-wrapper">
        <BoardMenuItem
            icon="file-copy-line"
            title="Скопировать"
            :action="() => { this.canvas.copyActiveObjects(); }"
        />
        <div class="ytm-board-menu-divider"
             v-if="active && (selectionParams.stroke.shouldShow || selectionParams.fill.shouldShow || selectionParams.text.shouldShow)"
        />
        <div v-if="active && selectionParams.stroke.shouldShow" class="ytm-board-menu-dropdown">
            <BoardMenuItem
                icon="edit-2-fill"
                title="Цвет обводки"
                :action="() => { }"
            />
            <div class="ytm-board-menu-dropdown-content-wrapper">
                <div class="ytm-board-menu-dropdown-content">
                    <BoardMenuItem
                        v-for="color in colors"
                        :key="color.name"
                        :icon="color.value !== 'white' ? 'drop-fill' : 'drop-line'"
                        :title="color.name"
                        :action="() => { this.canvas.ytmCanvas.setStrokeColorToSelected(color.value); }"
                        :isActive="() => { return this.selectionParams.stroke.color === color.value; }"
                        :color="color.value === 'white' ? 'black' : color.value"
                        style="margin-right: 0"
                    />
                </div>
            </div>
        </div>
        <div v-if="active && selectionParams.fill.shouldShow" class="ytm-board-menu-dropdown">
            <BoardMenuItem
                icon="paint-fill"
                title="Цвет заливки"
                :action="() => { }"
            />
            <div class="ytm-board-menu-dropdown-content-wrapper">
                <div class="ytm-board-menu-dropdown-content">
                    <BoardMenuItem
                        v-for="color in colors"
                        :key="color.name"
                        :icon="color.value !== 'white' ? 'drop-fill' : 'drop-line'"
                        :title="color.name"
                        :action="() => { this.canvas.ytmCanvas.setFillColorToSelected(color.value); }"
                        :isActive="() => { return this.selectionParams.fill.color === color.value; }"
                        :color="color.value === 'white' ? 'black' : color.value"
                        style="margin-right: 0"
                    />
                </div>
            </div>
        </div>
        <div v-if="active && selectionParams.text.shouldShow" class="ytm-board-menu-dropdown">
            <BoardMenuItem
                icon="font-color"
                title="Цвет текста"
                :action="() => { }"
            />
            <div class="ytm-board-menu-dropdown-content-wrapper">
                <div class="ytm-board-menu-dropdown-content">
                    <BoardMenuItem
                        v-for="color in colors"
                        :key="color.name"
                        :icon="color.value !== 'white' ? 'drop-fill' : 'drop-line'"
                        :title="color.name"
                        :action="() => { this.canvas.ytmCanvas.setFillColorToSelected(color.value); }"
                        :isActive="() => { return this.selectionParams.text.color === color.value; }"
                        :color="color.value === 'white' ? 'black' : color.value"
                        style="margin-right: 0"
                    />
                </div>
            </div>
        </div>
        <div class="ytm-board-menu-divider"
             v-if="active && selectionParams.lockState.shouldShow"
        />
        <BoardMenuItem
            :icon="selectionParams.lockState.locked ? 'lock-line' : 'lock-unlock-line'"
            :title="selectionParams.lockState.locked ? 'Разблокировать' : 'Заблокировать'"
            :action="() => { this.canvas.ytmCanvas.setLockedToSelected(!selectionParams.lockState.locked); }"
        />
    </div>
</template>

<script>
import "./menu.css";
import BoardMenuItem from "./BoardMenuItem";

export default {
    name: 'ContextMenu',
    components: {
        BoardMenuItem,
    },
    props: {
        canvas: {
            type: Object,
            required: true,
        },
        active: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            show_palette: false,
            selectionParams: {
                stroke: {},
                fill: {},
                text: {},
                lockState: {},
            },
            colors: [
                { name: 'Красный', value: 'red' },
                { name: 'Розовый', value: 'pink' },
                { name: 'Оранжевый', value: '#ffc107' },
                { name: 'Жёлтый', value: 'yellow' },
                { name: 'Лаймовый', value: 'lime' },
                { name: 'Зеленый', value: 'green' },
                { name: 'Голубой', value: 'aqua' },
                { name: 'Синий', value: 'blue' },
                { name: 'Фиолетовый', value: 'violet' },
                { name: 'Белый', value: 'white' },
                { name: 'Серый', value: 'gray' },
                { name: 'Чёрный', value: 'black' },
            ],
        };
    },
    mounted() {
        this.selectionParams = this.canvas.ytmCanvas.selectionParams;
    },
};
</script>

<style scoped>

</style>