<template>
  <div>
    <div class="ytm-chat-area">
      <div v-if="loading">
        <div class="skeleton ytm-message ytm-message-in-skeleton"/>
        <div class="skeleton ytm-message ytm-message-out-skeleton"/>
        <div class="skeleton ytm-message ytm-message-out-skeleton"/>
        <div class="skeleton ytm-message ytm-message-in-skeleton"/>
        <div class="skeleton ytm-message ytm-message-out-skeleton"/>
      </div>
      <div v-else-if="messages.length !== 0" v-for="message in messages.slice().reverse()">
        <div :class="{
          'ytm-default-text': true,
          'ytm-message': true,
          'ytm-message-out': message.from.username === username,
          'ytm-message-in': message.from.username !== username,
          }"
        >
          <p class="ytm-message-author">{{ message.from.name }}</p>
          <p>{{ message.text }}</p>
        </div>
      </div>
      <div v-else style="width: 100%; height: 100%; text-align: center; display: table">
        <p class="ytm-default-text ytm-no-messages-alert" style="display: table-cell; vertical-align: middle">
          Пока нет сообщений
        </p>
      </div>
    </div>
  </div>
  <div class="ytm-message-input">
    <Input v-model="message" type="text" placeholder="Сообщение" @keyup.enter="sendMessage"/>
    <div class="ytm-black-button" style="display: table; margin-left: 0.3rem" @click="sendMessage">
      <span class="ytm-default-text" style="display: table-cell; vertical-align: middle; font-weight: 600; color: #FFFFFF">
        Отправить
      </span>
    </div>
  </div>
</template>

<script>
import "@/components/CoursePageV2/skeletons.css";
import axios from "axios";
import {SERVICE_MESSENGER_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import {inject} from "vue";
import Input from "@/components/redesign/Form/Input.vue";

export default {
  name: 'Chat',
  components: {Input},
  props: {
    chat_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      username: '',
      message: '',
      messages: [],
      loading: false,
    };
  },
  setup() {
    const is_chat_focused = inject('is_chat_focused');
    return {is_chat_focused};
  },
  watch: {
    is_chat_focused: {
      handler: function(val) {
        if (document.visibilityState === 'visible') {
          this.focusOn(val ? 'focused' : 'visible');
        }
      },
    },
  },
  methods: {
    initializeConnection() {
      document.addEventListener('visibilitychange', this.visibilityChangeListener);
      this.focusOn(this.is_chat_focused ? 'focused' : 'visible');
    },
    visibilityChangeListener(event) {
      if (document.visibilityState === 'visible') {
        this.focusOn(this.is_chat_focused ? 'focused' : 'visible');
      } else {
        this.focusOn('invisible');
      }
    },
    focusOn(new_state) {
      this.$store.dispatch('ws/refocusChat', {
        chat_id: this.chat_id,
        new_state: new_state[0],
      });
      if (new_state === 'focused') {
        this.confirmRead();
      }
    },
    sendMessage() {
      if (!this.message) {
        return;
      }
      this.$store.dispatch('ws/sendChatMessage', {
        chat_id: this.chat_id,
        text: this.message,
      });
      this.message = '';
    },
    confirmRead() {
      this.$store.dispatch('ws/readChat', {
        chat_id: this.chat_id,
        date: new Date(),
      });
    },
    onNewSocketMessage(t, payload) {
      if (t === 1001) {
        this.messages.push(payload);
        this.confirmRead();
      }
    },
    stopConnection() {
      document.removeEventListener('visibilitychange', this.visibilityChangeListener);
      this.$store.dispatch('ws/unregisterChat', this);
      this.is_chat_focused = false;
    },
  },
  created() {
    this.$store.dispatch('auth/autoLogin').then(() => {
      this.username = this.$store.getters['auth/username'];
      this.loading = true;
      axios.post(
          SERVICE_MESSENGER_URI + '/getMessages',
          {
            chatId: this.chat_id,
          },
          {
            headers: authHeader(),
          },
      ).then(resp => {
        this.messages = resp.data.out;
        this.$store.dispatch('ws/registerChat', this);
        this.initializeConnection();
        this.loading = false;
      }).catch(err => {
        console.error(err);
      });
    });
  },
  beforeUnmount() {
    this.stopConnection();
  },
};
</script>

<style>
.ytm-chat-area {
  border: 1px solid #DDDDDD;
  background: white;
  height: 60vh;
  padding: 1rem;
  overflow: auto;
  border-radius: 1rem;
  width: 100%;
  margin: 0 auto 1rem auto;
  display: flex;
  flex-direction: column-reverse;
}
.ytm-message {
  width: 45%;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  font-size: .8em;
}
.ytm-message-out {
  border-radius: 1rem 1rem 0 1rem;
  background: #EDF6FB;
  margin-left: 55%;
}
.ytm-message-in {
  border-radius: 0 1rem 1rem 1rem;
  background: #F1F0F0;
}
.ytm-message-out-skeleton {
    margin-left: 55%;
    height: 3.5rem;
    border-radius: 1rem 1rem 0 1rem;
}
.ytm-message-in-skeleton {
    height: 3.5rem;
    border-radius: 0 1rem 1rem 1rem;
}
.ytm-message-author {
  font-weight: 600;
  margin-bottom: 0.1rem;
}
.ytm-no-messages-alert {
  font-size: 0.8rem;
  font-weight: 400;
}
.ytm-message-input {
  display: flex;
  margin-bottom: 2rem;
}
</style>
