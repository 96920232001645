<template>
    <div v-if="show" class="ytm-canvas-resizer-wrapper">
        <div
            ref="resizer"
            class="ytm-canvas-resizer"
            @touchstart="onTouchStart"
            @touchmove="onTouchMove"
            @touchend="onTouchEnd"
            @mousedown="startResizing"
            @mousemove="onResizing"
            @mouseup="stopResizing"
            @mouseleave="stopResizing"
        >
            <div class="ytm-canvas-resizer-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="height: 1.25rem; width: 1.25rem">
                    <path fill="none" d="M0 0h24v24H0z"/>
                    <path d="M5 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm14 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-7 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import "@/components/redesign/Board/board.css";

export default {
    name: 'Resizer',
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            resizing: false,
            prevTouchY: null,
        };
    },
    methods: {
        onTouchStart(evt) {
            if (evt.cancelable) {
                evt.preventDefault();
            }
            this.resizing = true;
            this.prevTouchY = evt.targetTouches[0].pageY;
        },
        onTouchMove(evt) {
            if (this.resizing && this.prevTouchY) {
                const curTouchY = evt.targetTouches[0].pageY;
                if (curTouchY !== this.prevTouchY) {
                    this.$emit('changeHeight', curTouchY - this.prevTouchY);
                    this.prevTouchY = curTouchY;
                }
            }
        },
        onTouchEnd() {
            if (this.resizing) {
                this.resizing = false;
                this.$emit('fixHeight');
            }
        },
        startResizing() {
            this.$refs.resizer.style.height = '10rem';
            this.$refs.resizer.style.bottom = '-4.375rem';
            this.$refs.resizer.style.cursor = 'grabbing';
            this.resizing = true;
        },
        onResizing(evt) {
            if (this.resizing && evt.movementY !== 0) {
                this.$emit('changeHeight', evt.movementY);
            }
        },
        stopResizing() {
            if (this.resizing) {
                this.$refs.resizer.style.height = '1.25rem';
                this.$refs.resizer.style.bottom = '0';
                this.$refs.resizer.style.cursor = 'grab';
                this.resizing = false;
                this.$emit('fixHeight');
            }
        },
    },
};
</script>

<style scoped>

</style>