<template>
    <div>
        <div v-if="image_to_show !== null">
            <div
                    class="ytm-image-wrapper"
                    ref="wrapper"
            >
                <button
                        class="ytm-image-navigation ytm-image-navigation-left"
                        @click="image_to_show = (images.length + image_to_show - 1) % images.length"
                >
                    <i
                            class="fa fa-chevron-left"
                            aria-hidden="true"
                            style="color: lightgray; font-size: 2rem"
                    />
                </button>
                <img
                        alt="Image"
                        :src="images[image_to_show].url"
                        class="ytm-image-view"
                />
                <button
                        class="ytm-image-navigation ytm-image-navigation-right"
                        @click="image_to_show = (image_to_show + 1) % images.length"
                >
                    <i
                            class="fa fa-chevron-right"
                            aria-hidden="true"
                            style="color: lightgray; font-size: 2rem"
                    />
                </button>
            </div>
        </div>
        <Carousel
                :n_slides="images.length"
                :per_page="4"
                :pagination="true"
                v-slot="{cur_slide}"
        >
            <Slide
                    v-for="(image, index) in images"
                    :key="image.url"
                    :per_page="4"
                    :cur_slide="cur_slide"
            >
                <img
                        :src="image.url"
                        alt="Image"
                        class="ytm-gallery-image"
                        @click="openImage(index)"
                />
            </Slide>
        </Carousel>
    </div>
</template>

<script>
import Carousel from "@/components/common/Carousel/Carousel.vue";
import Slide from "@/components/common/Carousel/Slide.vue";

export default {
    name: 'Gallery',
    components: {Slide, Carousel},
    props: {
        images: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            image_to_show: null,
        };
    },
    methods: {
        openImage(index) {
            this.image_to_show = index;
        },
        closeImage() {
            this.image_to_show = null;
        },
    },
    mounted() {
        let vm = this;
        document.addEventListener('click', function (item) {
            if (item.target === vm.$refs['wrapper']) {
                vm.closeImage();
            }
        });
    },
};
</script>

<style scoped>
.ytm-gallery-image {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    object-fit: cover;
    cursor: pointer;
}

.ytm-image-wrapper {
    z-index: 14;
    background-color: rgba(41, 41, 41, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.ytm-image-view {
    width: auto;
    max-width: 80vw;
    height: auto;
    max-height: 80vh;
    border-radius: 3px;
}

.ytm-image-navigation {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1002;
    display: block;
    background: rgba(0, 0, 0, 0.8);
    border: 0;
    line-height: 0;
    outline: none;
    padding: 7px;
    cursor: pointer;
}

.ytm-image-navigation-left {
    left: 1.5%;
}

.ytm-image-navigation-right {
    right: 1.5%;
}
</style>