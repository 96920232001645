import {Deque} from "./deque";

export class HistoryStorage {
    constructor(depth) {
        this.depth = Math.max(1, depth);
        this.past = new Deque();
        this.future = new Deque();
    }

    newAction(action) {
        this.future.clear();
        this.past.pushBack(action);
        this._adjustDepth();
    }

    goBackwards() {
        if (this.past.length === 0) {
            return null
        }
        let action = this.past.popBack();
        this.future.pushFront(action);
        this._adjustDepth();
        return action;
    }

    goForward() {
        if (this.future.length === 0) {
            return null;
        }
        let action = this.future.popFront();
        this.past.pushBack(action);
        this._adjustDepth();
        return action;
    }

    print() {
        console.log('past:');
        this.past.print();
        console.log('future:');
        this.future.print();
    }

    _adjustDepth() {
        while (this.past.length > this.depth) { this.past.popFront(); }
        while (this.future.length > this.depth) { this.future.popBack(); }
    }
}