<template>
    <div>
        <template v-for="(item, index) in items">
            <div class="divider" v-if="item.type === 'divider'" :key="`divider${index}`"/>
            <MenuItem v-else :key="index" v-bind="item"/>
        </template>
    </div>
</template>

<script>
import MenuItem from '@/components/MaterialsV2/CollabText/MenuItem.vue';

export default {
    name: 'MenuBar',
    components: {
        MenuItem,
    },
    props: {
        editor: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            items: [
                {
                    icon: 'bold',
                    title: 'Жирный текст',
                    action: () => { this.editor.chain().focus().toggleBold().run(); this.editor.setEditable(true)},
                    isActive: () => this.editor.isActive('bold'),
                },
                {
                    icon: 'italic',
                    title: 'Курсив',
                    action: () => { this.editor.chain().focus().toggleItalic().run(); this.editor.setEditable(true)},
                    isActive: () => this.editor.isActive('italic'),
                },
                {
                    icon: 'strikethrough',
                    title: 'Зачеркнутый текст',
                    action: () => {this.editor.chain().focus().toggleStrike().run(); this.editor.setEditable(true)},
                    isActive: () => this.editor.isActive('strike'),
                },
                {
                    icon: 'code-view',
                    title: 'Код',
                    action: () => {this.editor.chain().focus().toggleCode().run(); this.editor.setEditable(true)},
                    isActive: () => this.editor.isActive('code'),
                },

                {
                    type: 'divider',
                },
                {
                    icon: 'link',
                    title: 'Ссылка',
                    action: () => {this.setLink(); this.editor.setEditable(true)},
                    isActive: () => this.editor.isActive('link'),
                },
                {
                    icon: 'link-unlink',
                    title: 'Отменить ссылку',
                    action: () => this.editor.chain().focus().unsetLink().run(),
                },
                {
                    icon: 'functions',
                    title: 'Математическая формула',
                    action: () => {this.editor.chain().focus().insertContent('<Formula/>').run(); this.editor.setEditable(true)},
                    isActive: () => this.editor.isActive('Formula'),
                },
            ],
        };
    },
    methods: {
        setLink() {
            const previousUrl = this.editor.getAttributes('link').href;
            const url = window.prompt('URL', previousUrl);
            if (url === null) {
                return;
            }
            if (url === '') {
                this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
            } else {
                this.editor.chain().focus().extendMarkRange('link').setLink({href: url, target: '_blank'}).run();
            }
        },
    },
};
</script>

<style>
.divider {
    width: 2px;
    height: 1.25rem;
    background-color: rgba(0, 0, 0, 0.1);
    margin-left: 0.5rem;
    margin-right: 0.75rem;
}
</style>