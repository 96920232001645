<template>
  <div style="position: relative; width: 100%; padding-right: 15px; padding-left: 15px">
    <div class="ytm-carousel-wrapper">
      <div class="ytm-carousel">
        <div v-if="per_page < n_slides" class="ytm-carousel-navigation">
          <div class="toggle-page left">
            <div style="padding: 5px; cursor: pointer" @click="prevSlide()">
              <i class="fa fa-chevron-left" aria-hidden="true"/>
            </div>
          </div>
          <div class="toggle-page right">
            <div style="padding: 5px; cursor: pointer" @click="nextSlide()">
              <i class="fa fa-chevron-right" aria-hidden="true"/>
            </div>
          </div>
        </div>
        <slot :cur_slide="cur_slide"/>
      </div>
      <div v-if="pagination && per_page < n_slides" class="ytm-carousel-pagination">
      <span
          v-for="index in n_dots"
          :key="index"
          @click="goToSlide(index)"
          :class="{ active: index - 1 === Math.floor((cur_slide + per_page - 1) / per_page) }"
      />
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import Container from "../Placement/Container";

export default {
  name: 'Carousel',
  components: {Container},
  props: {
    n_slides: {
      type: Number,
      required: true,
    },
    per_page: {
      type: Number,
      default: () => 3,
    },
    pagination: {
      type: Boolean,
      default: () => false,
    },
  },
  setup() {
    let cur_slide = ref(0);
    return { cur_slide };
  },
  data() {
    return {
      n_dots: 1,
    };
  },
  methods: {
    prevSlide() {
      if (this.cur_slide + this.per_page === this.n_slides && this.cur_slide % this.per_page !== 0) {
        this.cur_slide -= this.cur_slide % this.per_page;
      } else {
        this.cur_slide -= this.per_page;
      }
      this.normalize();
    },
    nextSlide() {
      this.cur_slide += this.per_page;
      this.normalize();
    },
    goToSlide(index) {
      this.cur_slide = (index - 1) * this.per_page;
      this.normalize();
    },
    normalize() {
      if (this.cur_slide + this.per_page >= this.n_slides) {
        this.cur_slide = this.n_slides - this.per_page;
      }
      if (this.cur_slide < 0) {
        this.cur_slide = 0;
      }
    },
  },
  mounted() {
    this.n_dots = Math.floor((this.n_slides + this.per_page - 1) / this.per_page);
  },
};
</script>

<style scoped>
.ytm-carousel-wrapper {
  max-width: 100%;
  margin-left: 16px;
  margin-right: 16px;
  overflow: hidden;
}

.ytm-carousel {
  display: flex;
}

.ytm-carousel-navigation {
  height: 100%;
  width: 100%;
  margin-left: -32px;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.ytm-carousel-pagination {
  position: relative;
  width: 100%;
  display: flex;
  margin-top: 35px;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

span {
  cursor: pointer;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: lightgray;
}

@media only screen and (max-width: 1200px) {
  span {
    width: 10px;
    height: 10px;
  }
}

@media only screen and (max-width: 800px) {
  span {
    width: 8px;
    height: 8px;
  }
}

.active {
  background-color: black;
}

.toggle-page {
  display: flex;
  flex: 1;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

i {
  cursor: pointer;
}
</style>
