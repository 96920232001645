<template>
  <textarea
      :value="modelValue"
      @input="updateInput"
      class="ytm-textarea"
  />
</template>

<script>
export default {
  name: 'TextArea',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>

<style scoped>
.ytm-textarea {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  overflow: auto;
  resize: vertical;
}

.ytm-textarea:focus {
  outline-color: lightgray;
}
</style>