<template>
  <Modal @reset_modal="$emit('reset_modal')">
    <div class="ytm-default-text" style="font-size: 1.75rem; font-weight: 600; text-align: center; margin-bottom: 1.75rem">
      Загрузить изображение
    </div>
    <div
        class="ytm-images-load-field"
        @drop="onFilesDropped($event)"
        @dragover.prevent
        @drop.prevent
        style="display: table; margin-bottom: 0.5rem"
    >
      <p class="ytm-default-text" style="font-weight: 400; color: #9E9E9E; display: table-cell; vertical-align: middle">
        Перетащите сюда файл или кликните, чтобы открыть проводник
      </p>
      <input
          class="ytm-file-input"
          type="file"
          accept="image/png,image/x-png,image/gif,image/jpeg"
          @change="onFilesSelected($event)"
      />
    </div>
    <div style="margin-top: 0.5rem">
      <p v-if="file" class="ytm-default-text">
        {{file.name}}
      </p>
    </div>
    <Input
        v-model="url"
        @update:modelValue="removeFile"
        placeholder="Или вставьте ссылку на картинку"
        style="margin-top: 1rem"
    />
    <p v-if="errorMsg" class="ytm-default-text" style="margin-top: 1rem; margin-bottom: -1rem; text-align: center; color: red">
      {{errorMsg}}
    </p>
    <div v-show="file || url" style="margin-top: 1.5rem; width: 100%; display: flex; justify-content: center">
      <div
          class="ytm-blue-button ytm-default-text"
          style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
          @click="uploadImage"
      >
        {{!loading ? 'Загрузить' : 'Загружаем...'}}
      </div>
    </div>
  </Modal>
</template>

<script>
import "@/styles/style.css";
import Modal from "@/components/redesign/Modal";
import Input from "@/components/redesign/Form/Input";
import axios from "axios";
import {authHeader} from "@/util/auth-header";
import {SERVICE_MATERIALS_URI} from "@/util/api-host";

export default {
  name: 'ImageUploadModal',
  components: {Input, Modal},
  props: {
    boardId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      file: null,
      url: '',
      loading: false,
      errorMsg: '',
    };
  },
  methods: {
    onFilesSelected(event) {
      if (event.target.files.length > 0) {
        this.file = event.target.files[0];
        this.url = '';
      }
    },
    onFilesDropped(event) {
      if (event.dataTransfer.files.length > 0) {
        this.file = event.dataTransfer.files[0];
        this.url = '';
      }
    },
    removeFile() {
      if (this.url.length > 0) {
        this.file = null;
      }
    },
    uploadImage() {
      if (this.url && this.url.match(/\.(jpeg|jpg|gif|png)$/)) {
        this.$emit('imageUploaded', this.url);
        this.$emit('reset_modal');
      } else {
        this.$store.dispatch('auth/autoLogin').then(() => {
          if (this.loading) {
            return;
          }
          this.loading = true;
          let headers = authHeader();
          let payload = new FormData();
          payload.append('images[]', this.file);
          headers = {
            ...headers,
            'Content-Type': 'multipart/form-data',
          };
          axios.post(SERVICE_MATERIALS_URI + '/uploadImages',
              payload,
              {
                headers: headers,
                params: {materialsId: this.boardId},
              },
          ).then(resp => {
            const uploadedUrl = resp.data.out[0];
            this.$emit('imageUploaded', uploadedUrl);
            this.$emit('reset_modal');
            this.loading = false;
          }).catch(err => {
            this.errorMsg = 'Ошибка загрузки';
            this.loading = false;
            console.error(err);
          });
        });
      }
    },
  },
};
</script>

<style scoped>
.ytm-images-load-field {
  position: relative;
  border: 1px dashed #9E9E9E;
  border-radius: 0.8rem;
  width: 100%;
  min-height: 5rem;
  background: rgba(238, 238, 238, 0.5);
  text-align: center;
  cursor: pointer;
}

.ytm-file-input {
  opacity: 0.0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height:100%;
  cursor: pointer;
}
</style>