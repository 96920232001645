<template>
    <TransitionGroup name="ytm-vertical-list" @leave="leave">
        <slot/>
    </TransitionGroup>
</template>

<script>
export default {
    name: 'VerticalListTransition',
    methods: {
        leave(element) {
            if (!element.style.height || element.style.height === 'auto') {
                const height = getComputedStyle(element).height;
                element.style.height = height;
                getComputedStyle(element).height;
            }
            requestAnimationFrame(() => {
                element.style.height = 0;
            });
        },
    },
};
</script>

<style>
.ytm-vertical-list-enter-active, .ytm-vertical-list-leave-active {
    transition: height .3s ease-in-out;
    overflow: hidden;
}
.ytm-vertical-list-enter, .ytm-vertical-list-leave-to {
    height: 0;
}
</style>